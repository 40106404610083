<template>
  <div id="app">
    <header class="app-header">
      <h1 class="app-titolo">il Contagoverni</h1>
      <p class="app-claim">
        Gli anni passano, i governi di pi&#xF9;.<br />
        La monotona stabilit&#xE0; parlamentare &#xE8; solo un vezzo per vecchie democrazie.<br />
        Noi preferiamo il brivido costante del cambiamento.<br />
        Ecco tutti i governi della tua vita.
      </p>
    </header>

    <form class="form" @submit.prevent="filterData">
      <label class="form__label" for="date" v-html="`Data di nascita`" />

      <div class="form__elements">
        <input type="date" id="date" ref="chosenDate" />

        <button @click="filterData">
          🔍<span class="visually-hidden">Cerca</span>
        </button>
        <button @click="clearData">
          ❌<span class="visually-hidden">Annulla</span>
        </button>
      </div>
    </form>

    <div v-if="governiFiltered.length" class="risultati container">
      <h2 class="risultati__titolo">In <strong>{{ age() }}</strong> anni, la Repubblica italiana &#xE8; riuscita a formare <strong>{{ governiFiltered.length }}</strong> governi</h2>
      <div class="risultati__lista">
        <div v-for="(governo, index) in governiFiltered" :key="index" class="governi-list__governo governo">
          <p class="governo__periodo" v-html="`${formatDate(governo.mandato_inizio)} - ${formatDate(governo.mandato_fine)}`" />
          <h3 class="governo__nome" v-html="`Governo<br/>${governo.nome}`" />
          <p class="governo__giorni" v-html="`${daysLived(governo)} giorni su ${duration(governo)}`" />
          <hr />
          <p class="governo__composizione" v-html="`<strong>COMPOSIZIONE</strong><br>${governo.composizione}`" />
        </div>
      </div>
    </div>

    <footer>
      <p class="app-quote">
        Il valore delle cose non sta nel tempo in cui esse durano ma nell&#x27;intensit&#xE0; con cui vengono vissute (F. Pessoa)
      </p>
      <p class="app-credits">Codice by <a href="https://www.k12m.it/" target="_blank">k12m</a> - Copy by Giacomo</p>
    </footer>
  </div>
</template>

<script>
import governiCSV from './assets/governi.csv'
import { isAfter, parseISO, differenceInDays, differenceInYears, format } from 'date-fns'

export default {
  name: 'App',
  data() {
    return {
      chosenDate: null,
      governiFiltered: [],
      governi: []
    }
  },
  methods: {
    parseCSV() {
      this.governi = governiCSV
    },

    formatDate(date) {
      return (date) ? format(parseISO(date), 'dd/MM/yyyy') : 'oggi'
    },

    filterData() {
      this.chosenDate = this.$refs.chosenDate.value

      if (!this.chosenDate) {
        return;
      }

      this.governiFiltered = this.governi.filter(governo =>
        !isAfter(parseISO(this.chosenDate), parseISO(governo.mandato_inizio)) ||
        (isAfter(parseISO(this.chosenDate), parseISO(governo.mandato_inizio)) && !isAfter(parseISO(this.chosenDate), parseISO(governo.mandato_fine)))
      )
    },

    clearData() {
      this.governiFiltered = []
      this.chosenDate = null
      this.$refs.chosenDate.value = null
    },

    daysLived(governo) {
      const endDate = (governo.mandato_fine) ? parseISO(governo.mandato_fine) : new Date()
      const chosenDate = (isAfter(parseISO(this.chosenDate), parseISO(governo.mandato_inizio))) ? parseISO(this.chosenDate) : parseISO(governo.mandato_inizio)
      return differenceInDays(endDate, chosenDate)
    },

    duration(governo) {
      const endDate = (governo.mandato_fine) ? parseISO(governo.mandato_fine) : new Date()
      return differenceInDays(endDate, parseISO(governo.mandato_inizio))
    },

    age() {
      const today = new Date();
      return differenceInYears(today, parseISO(this.chosenDate));
    }
  },
  mounted() {
    this.parseCSV()
  }
}
</script>

<style lang="scss">
@import url('./assets/reset.css');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');

$color_base: #303030;
$color_light: #CCC;

html {
  font-size: 16px;

  @media only screen and (max-width: 600px) {
    font-size: 14px;
  }
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

body {
  font-family: Garamond,Baskerville,Baskerville Old Face,Hoefler Text,Times New Roman,serif;
  background: linear-gradient(to bottom, #fae7cd, #fef7ed);
  background-attachment: fixed;
  color: $color_base;

  #app {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 100vh;
    padding-top: 3rem;
  }

  .container {
    margin: auto;
    max-width: 1024px;
    width: 90%;
  }

  strong {
    font-weight: bold;
  }

  footer {
    margin-top: auto;
    margin-bottom: 1rem;
    padding-top: 2rem;
    text-align: center;
  }

  .app-header {
    margin: 0 auto 2rem;
    max-width: 700px;
    width: 90%;
  }

  .app-titolo {
    font-family: 'Abril Fatface', cursive;
    font-size: clamp(1.5rem, 10vw, 4rem);
    font-weight: 700;
    letter-spacing: 0.05em;
    margin: 0 0 2rem;
    text-align: center;
  }

  .app-quote,
  .app-claim {
    line-height: 1.1;
  }

  .app-quote {
    font-size: 1.2rem;
    font-style: italic;
    margin-bottom: 0.5em;
    padding: 0 3%;
  }

  .app-credits {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 0.7rem;

    a {
      color: $color_base;
    }
  }

  .app-claim {
    font-size: 1.3rem;
    text-align: center;
  }

  .form {
    display: block;
    margin: 0 auto;
    text-align: center;

    input, button {
      border-radius: 0;
      font-family: Garamond,Baskerville,Baskerville Old Face,Hoefler Text,Times New Roman,serif;
      font-size: 1rem;
    }

    label {
      font-size: 1.2rem;
      font-weight: bold;
      display: block;
      margin-bottom: 0.5rem;
      text-transform: uppercase;
    }

    input {
      border: none;
      height: 3rem;
      padding-left: 10px;
      width: 200px;
    }

    button {
      background: transparent;
      border: none;
      cursor: pointer;
      font-weight: 700;
      height: 3rem;
      margin: auto;
      padding: 0 10px;
    }

    &__elements {
      background: #fff;
      border: 1px solid;
      display: flex;
      align-content: center;
    }
  }

  .risultati {
    &__titolo {
      margin: 2rem 0;
      font-size: 2rem;
      text-align: center;
    }

    &__lista {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 20px;
    }

    @media only screen and (max-width: 600px) {
      &__lista {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 20px;
      }
    }
  }

  .governo {
    background: white;
    box-shadow: 0 2px 4px rgba(0,0,0,.25);
    padding: 20px;

    &__nome {
      font-size: 1.6rem;
      font-weight: 700;
      margin-bottom: 0.75rem;
      text-align: center;
      text-transform: uppercase;
    }

    &__periodo {
      font-style: italic;
      margin-bottom: 0.75rem;
      text-align: center;
    }

    &__giorni {
      text-align: center;
    }

    &__composizione {
      line-height: 1.2;
      text-align: center;
    }

    hr {
      border: none;
      border-bottom: 1px solid $color_light;
      margin: 1rem 0;
    }
  }
}
</style>
